<div class="tab-container tab-danger">
   @if (filterTypes) {
      <div class="filter-selection-bar">
         <div>
            <lim-ui-icon icon="filter" iconSize="small" />
            {{ lang().Filter }}
         </div>
         @if (filterTypes.has("dateRange")) {
            <lim-ui-dropdown-button [label]="lang().DateRange">
               <div menu>
                  @if (dashboardReportingLimit === 30) {
                     <lim-ui-dropdown-item
                        (click)="$event.stopPropagation()"
                        [limUiPopover]="startDateRangeLast30DaysPopover"
                        [limUiPopoverType]="'upsell'"
                        [limUiPopoverHidden]="dashboardReportingLimit !== 30"
                        [limUiPopoverPlacement]="'right'"
                     >
                        <div class="pricing-plan-text-and-badge">
                           <span class="paywalled">{{ lang().LastMonth }}</span>
                           <lim-ui-badge icon="stars" />
                        </div>
                     </lim-ui-dropdown-item>
                  }

                  @if (dashboardReportingLimit === 90) {
                     <lim-ui-dropdown-item
                        (click)="$event.stopPropagation()"
                        [limUiPopover]="startDateRangeLast90DaysPopover"
                        [limUiPopoverType]="'upsell'"
                        [limUiPopoverHidden]="dashboardReportingLimit !== 90"
                        [limUiPopoverPlacement]="'right'"
                     >
                        <div class="pricing-plan-text-and-badge">
                           <span class="paywalled">{{ lang().Last3Months }}</span>
                           <lim-ui-badge icon="stars" />
                        </div>
                     </lim-ui-dropdown-item>
                  }

                  @if (dashboardReportingLimit !== undefined) {
                     <lim-ui-dropdown-item
                        (click)="$event.stopPropagation()"
                        [limUiPopover]="additionalFiltersPopover"
                        [limUiPopoverType]="'upsell'"
                        [limUiPopoverHidden]="dashboardReportingLimit === undefined"
                        [limUiPopoverPlacement]="'right'"
                     >
                        <div class="pricing-plan-text-and-badge">
                           <span class="paywalled">{{ lang().AdditionalFilters }}</span>
                           <lim-ui-badge icon="stars" />
                        </div>
                     </lim-ui-dropdown-item>
                  }

                  @if (!dashboardReportingLimit) {
                     <lim-ui-dropdown-text-item
                        (click)="setDateRangeLastXMonths(3)"
                        [displayString]="lang().Last3Months"
                     />
                  }

                  @if (!dashboardReportingLimit) {
                     <lim-ui-dropdown-text-item
                        (click)="setDateRangeLastXMonths(6)"
                        [displayString]="lang().Last6Months"
                     />
                  }

                  @if (!dashboardReportingLimit) {
                     <lim-ui-dropdown-text-item
                        (click)="setDateRangeLastXMonths(9)"
                        [displayString]="lang().Last9Months"
                     />
                  }
                  @if (!dashboardReportingLimit) {
                     <lim-ui-dropdown-text-item
                        (click)="setDateRangeLastXMonths(12)"
                        [displayString]="lang().Last12Months"
                     />
                  }
                  @if (!dashboardReportingLimit) {
                     <lim-ui-dropdown-text-item
                        (click)="setDateRangeThisYear()"
                        [displayString]="lang().ThisYear"
                     />
                  }
                  @if (!dashboardReportingLimit) {
                     <lim-ui-dropdown-text-item
                        (click)="setDateRangeLastYear()"
                        [displayString]="lang().LastYear"
                     />
                  }

                  <lim-ui-dropdown-divider />
                  <lim-ui-dropdown-date-range-picker
                     [sectionLabel]="lang().DateRange"
                     [startDateFieldLabel]="lang().From"
                     [(startDate)]="dateRangeStart"
                     [endDateFieldLabel]="lang().To"
                     [(endDate)]="dateRangeEnd"
                     [setButtonLabel]="lang().SetDateRange"
                     (setButtonClicked)="setCustomDateRange($event)"
                  />
               </div>
               <div footer>
                  <lim-ui-dropdown-divider />
                  <lim-ui-dropdown-clear-filter-item (click)="clearFilter('dateRange')">
                     {{ lang().ClearFilter }}
                  </lim-ui-dropdown-clear-filter-item>
               </div>
            </lim-ui-dropdown-button>
         }
         <ng-template #startDateRangeLast90DaysPopover>
            <upsell-popover
               [message]="lang().FeatureLimited90DashboardsPopoverMessage"
               [pricingLinkText]="lang().LearnMore"
            />
         </ng-template>
         <ng-template #additionalFiltersPopover>
            <upsell-popover
               [message]="
                  dashboardReportingLimit === 30
                     ? lang().FeatureLimited30DashboardsPopoverMessage
                     : lang().FeatureLimited90DashboardsPopoverMessage
               "
               [pricingLinkText]="lang().LearnMore"
            />
         </ng-template>
         <ng-template #startDateRangeLast30DaysPopover>
            <upsell-popover
               [message]="
                  dashboardReportingLimit == 30
                     ? lang().FeatureLimited30DashboardsPopoverMessage
                     : lang().FeatureLimited90DashboardsPopoverMessage
               "
               [pricingLinkText]="lang().LearnMore"
            />
         </ng-template>

         @if (filterTypes.has("location")) {
            <lim-ui-dropdown-button [label]="lang().Locations">
               @if (locations.length > 5) {
                  <div header>
                     <lim-ui-search-box
                        [placeholder]="lang().Search"
                        [(searchVal)]="searchLocations"
                        (searchValChange)="buildLocationsDropdown()"
                        [useDropdownStyling]="true"
                     />
                     <lim-ui-dropdown-divider />
                  </div>
               }
               <div menu>
                  @for (location of locationsDropdown; track location) {
                     <lim-ui-dropdown-item (click)="setLocationFilter(location)">
                        <div [limbleHtml]="location.locationNameWithRegionsIcon"></div>
                     </lim-ui-dropdown-item>
                  }
               </div>
               <div footer>
                  <lim-ui-dropdown-divider />
                  <lim-ui-dropdown-clear-filter-item (click)="clearFilter('location')">
                     {{ lang().ClearFilter }}
                  </lim-ui-dropdown-clear-filter-item>
               </div>
            </lim-ui-dropdown-button>
         }
         @if (filterTypes.has("taskType")) {
            <lim-ui-dropdown-button [label]="lang().Type">
               <div menu>
                  <lim-ui-dropdown-item
                     (click)="setTaskTypeFilter(TaskType.PlannedMaintenances)"
                  >
                     {{ lang().PMs }}
                  </lim-ui-dropdown-item>
                  <lim-ui-dropdown-item
                     (click)="setTaskTypeFilter(TaskType.UnplannedWorkOrders)"
                  >
                     {{ lang().UnplannedWOs }}
                  </lim-ui-dropdown-item>
                  <lim-ui-dropdown-item
                     (click)="setTaskTypeFilter(TaskType.PlannedWorkOrders)"
                  >
                     {{ lang().PlannedWOs }}
                  </lim-ui-dropdown-item>
               </div>
               <div footer>
                  <lim-ui-dropdown-divider />
                  <lim-ui-dropdown-clear-filter-item (click)="clearFilter('taskType')">
                     {{ lang().ClearFilter }}
                  </lim-ui-dropdown-clear-filter-item>
               </div>
            </lim-ui-dropdown-button>
         }
         @if (filterTypes.has("user")) {
            <lim-ui-dropdown-button [label]="lang().User">
               <div menu>
                  @for (user of users; track user) {
                     <lim-ui-dropdown-item (click)="setUserFilter(user)">
                        {{ user.userFirstName + " " + user.userLastName }}
                     </lim-ui-dropdown-item>
                  }
                  <lim-ui-dropdown-divider />
                  <lim-ui-dropdown-clear-filter-item (click)="clearFilter('user')">
                     {{ lang().ClearFilter }}
                  </lim-ui-dropdown-clear-filter-item>
               </div>
            </lim-ui-dropdown-button>
         }
         @for (entry of activeFilters | keyvalue; track entry) {
            <div>
               <span (click)="clearFilter(entry.key)" class="active-filter">
                  @if (!dashboardReportingLimit) {
                     <lim-ui-clear-filter-button />
                  }
                  {{ lang().ActiveFilter }}:
                  <b [limbleHtml]="entry.value.displayString"> </b>
               </span>
            </div>
         }
      </div>
   }
</div>
