import type { WidgetDefinition } from "src/app/dashboards/types/customDashboard.types";

export function getDefaultEnglishWidgetDef(): WidgetDefinition {
   return {
      widgetID: 0,
      anyLocation: true,
      anyUser: true,
      anyUserCompleted: true,
      anyTeam: true,
      anyVendor: true,
      type: false,
      displayData: {},
      dateRange1: "",
      dateRange2: "",
      dateStr: "All Time",
      dateStrDisplay: "All Time",
      dateNextDays: 0,
      dateLastDays: 0,
      dateLastHours: 0,
      dateNextWeeks: 0,
      dateLastWeeks: 0,
      dateNextMonths: 0,
      dateLastMonths: 0,
      tileColor: "Red",
      tileColorDisplayName: "Color 1",
      unassignedUser: true,
      filterByAssignedTo: false,
      filterByCompletedBy: false,
      filterByLocation: false,
      filterByAssets: false,
      filterByParts: false,
      assetFields: [{ fieldName: "Select Field", id: 1 }],
      partFields: [{ fieldName: "Select Field", id: 1 }],
      viewedAs: "tile",
      name: "New Widget",
      additionalFilters: false,
      filterByVendor: false,
      locations: [],
      vendors: [],
      tasks: {
         statusObj: {},
         priority: "All",
         PMs: false,
         PWOs: false,
         UWOs: false,
         WRs: false,
         PTs: false,
         CCs: false,
         dueVsCompletedForTasks: "Created",
         dueVsCompletedForTasksDisplay: "Created",
         additionalFiltersCompletionTime: false,
         additionalFiltersCompletionTimeNumber: 1,
         additionalFiltersCompletionTimeFlip: "longer or equal to",
         additionalFiltersCompletionTimeFlipDisplay: "longer or equal to",
         additionalFiltersCompletionTimeAnd: false,
         additionalFiltersCompletionTimeNumber2: 1,
         additionalFiltersEstTime: false,
         additionalFiltersEstTimeNumber: 1,
         additionalFiltersEstTimeFlip: "longer or equal to",
         additionalFiltersEstTimeFlipDisplay: "longer or equal to",
         additionalFiltersEstTimeAnd: false,
         additionalFiltersEstTimeNumber2: 1,
         additionalFiltersDowntime: false,
         additionalFiltersDowntimeNumber: 1,
         additionalFiltersDowntimeFlip: "longer or equal to",
         additionalFiltersDowntimeFlipDisplay: "longer or equal to",
         additionalFiltersDowntimeAnd: false,
         additionalFiltersDowntimeNumber2: 1,
         additionalFiltersTotalOperatingCost: false,
         additionalFiltersTotalOperatingCostNumber: 0,
         additionalFiltersTotalOperatingCostFlip: "greater or equal to",
         additionalFiltersTotalOperatingCostFlipDisplay: "greater or equal to",
         additionalFiltersTotalOperatingCostAnd: false,
         additionalFiltersTotalOperatingCostNumber2: 0,
         additionalFiltersTotalPartsCost: false,
         additionalFiltersTotalPartsCostNumber: 0,
         additionalFiltersTotalPartsCostFlip: "greater or equal to",
         additionalFiltersTotalPartsCostFlipDisplay: "greater or equal to",
         additionalFiltersTotalPartsCostAnd: false,
         additionalFiltersTotalPartsCostNumber2: 0,
         additionalFiltersTotalLaborCost: false,
         additionalFiltersTotalLaborCostNumber: 0,
         additionalFiltersTotalLaborCostFlip: "greater or equal to",
         additionalFiltersTotalLaborCostFlipDisplay: "greater or equal to",
         additionalFiltersTotalLaborCostAnd: false,
         additionalFiltersTotalLaborCostNumber2: 0,
         additionalFiltersTotalInvoicesCost: false,
         additionalFiltersTotalInvoicesCostNumber: 0,
         additionalFiltersTotalInvoicesCostFlip: "greater or equal to",
         additionalFiltersTotalInvoicesCostFlipDisplay: "greater or equal to",
         additionalFiltersTotalInvoicesCostAnd: false,
         additionalFiltersTotalInvoicesCostNumber2: 0,
         additionalFiltersNumOfInvoices: false,
         additionalFiltersNumOfInvoicesNumber: 0,
         additionalFiltersNumOfInvoicesFlip: "greater or equal to",
         additionalFiltersNumOfInvoicesFlipDisplay: "greater or equal to",
         additionalFiltersNumOfInvoicesAnd: false,
         additionalFiltersNumOfInvoicesNumber2: 0,
         additionalFiltersNumOfNotes: false,
         additionalFiltersNumOfNotesNumber: 0,
         additionalFiltersNumOfNotesFlip: "greater or equal to",
         additionalFiltersNumOfNotesFlipDisplay: "greater or equal to",
         additionalFiltersNumOfNotesAnd: false,
         additionalFiltersNumOfNotesNumber2: 0,
         additionalFiltersNumOfDueDateChanges: false,
         additionalFiltersNumOfDueDateChangesNumber: 0,
         additionalFiltersNumOfDueDateChangesFlip: "greater or equal to",
         additionalFiltersNumOfDueDateChangesFlipDisplay: "greater or equal to",
         additionalFiltersNumOfDueDateChangesAnd: false,
         additionalFiltersNumOfDueDateChangesNumber2: 0,
         additionalFiltersCustomTag: false,
         additionalFiltersCustomTagValue: false,
         additionalFiltersCustomTagCondition: "with",
         additionalFiltersCustomTagCondition2: "all",
         additionalFiltersDueDate: false,
         additionalFiltersDueDateNumber: 1,
         additionalFiltersDueDateFlip: "past due by at least",
         additionalFiltersTaskName: false,
         additionalFiltersTaskNameValue: "",
         additionalFiltersStartedByUser: false,
         additionalFiltersStartedByUserSelectionDisplay: "Pick User",
         additionalFiltersRequestorInformation: false,
         additionalFiltersRequestorInformationValue: "",
         additionalFiltersTasksStartedInside: false,
         additionalFiltersTasksStartedInsideAnd: false,
         additionalFiltersTasksStartedInsideAndValue: "",
         additionalFiltersDueDateFlipDisplay: "past due by at least",
         additionalFiltersCompletedState: false,
         additionalFiltersCompletedStateSelection: "Overdue",
         additionalFiltersCompletedStateSelectionDisplay: "Overdue",
         additionalFiltersCompletedStateAnd: false,
         additionalFiltersCompletedStateSelection2: "On-time",
         additionalFiltersCompletedStateSelection2Display: "On-time",
         completionLongerThanEstimated: false,
         additionalFiltersOpenColorState: false,
         additionalFiltersOpenColorStateSelection: "Green",
         additionalFiltersOpenColorStateSelectionDisplay: "Green",
         additionalFiltersOpenColorStateAnd: false,
         additionalFiltersOpenColorStateSelection2: "Orange",
         additionalFiltersOpenColorStateSelection2Display: "Orange",
         aFRequestApprovalState: false,
         aFRequestApprovalStateSelection: "Disapproved",
         aFRequestApprovalStateSelectionDisplay: "Disapproved",
         aFRequestApprovalStateAnd: false,
         aFRequestApprovalStateSelection2: "Pending",
         aFRequestApprovalStateSelection2Display: "Pending",
      },
      usersData: {
         additionalFiltersCustomTag: false,
         additionalFiltersCustomTagValue: false,
         additionalFiltersCustomTagCondition: "with",
         additionalFiltersCustomTagCondition2: "all",
         additionalFiltersRequestorInformation: false,
      },
      display: {},
      POs: {
         budgets: {},
         GLs: {},
         dateType: "PO",
         dateTypePODisplay: "PO",
         aFSpecificParts: false,
         aFSpecificBudgets: false,
         budgetsDisplay: "",
         aFSpecificGLs: false,
         GLsDisplay: "",
         aFTasksWaitingonPO: false,
         listOption: false,
         afItemTypes: false,
         itemTypes: {},
         itemsTypeDisplay: "",
         parts: [],
         statuses: { "0": false, "97": false, "98": false, "99": false, "100": false },
      },
      parts: { afUnderstocked: false, afStaleThreshold: false },
      assets: {
         tools: true,
         haveDepreciationSchedule: false,
      },
   };
}
