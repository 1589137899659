<lim-ui-modal [showWatermark]="true" [small]="true">
   <lim-ui-basic-modal-header [title]="lang().Settings" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         <div class="roi-settings-content">
            <div class="form-group">
               <label>
                  {{ lang().DowntimeEfficiencyRate }}
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     limUiTooltip="{{ lang().DowntimeEffiencyRateTooltip }}"
                     placement="bottom"
                  />
               </label>
               <div class="input-and-unit-container">
                  <input
                     type="number"
                     class="form-control"
                     [(ngModel)]="downTimeEfficiencyRate"
                     min="-1"
                     onclick="this.focus();"
                  />
                  <lim-ui-minimal-icon-button
                     icon="rotateLeft"
                     placement="right"
                     [limUiTooltip]="lang().Reset"
                     (click)="resetToDefault('downtimeRate')"
                  />
               </div>
            </div>
            <div class="form-group">
               <label>
                  {{ lang().AverageDowntimeCost }}
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     limUiTooltip="{{ lang().AverageDowntimeCostTooltip }}"
                     placement="bottom"
                  />
               </label>
               <div class="input-and-unit-container">
                  <input
                     type="number"
                     class="form-control"
                     [(ngModel)]="averageDowntimeCost"
                     min="-1"
                     onclick="this.focus();"
                  />
                  <lim-ui-minimal-icon-button
                     icon="rotateLeft"
                     placement="right"
                     [limUiTooltip]="lang().Reset"
                     (click)="resetToDefault('downtimeCost')"
                  />
               </div>
            </div>
            <div class="form-group">
               <label>
                  {{ lang().LaborEfficiencyRate }}
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     limUiTooltip="{{ lang().LaborEfficiencyRateTooltip }}"
                     placement="bottom"
                  />
               </label>
               <div class="input-and-unit-container">
                  <input
                     type="number"
                     class="form-control"
                     [(ngModel)]="laborEfficiencyRate"
                     min="-1"
                     onclick="this.focus();"
                  />
                  <lim-ui-minimal-icon-button
                     icon="rotateLeft"
                     placement="right"
                     [limUiTooltip]="lang().Reset"
                     (click)="resetToDefault('laborRate')"
                  />
               </div>
            </div>
            <div class="form-group">
               <label>
                  {{ lang().partSpendEfficiencyRate }}
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     limUiTooltip="{{ lang().PartSpendEfficiencyRateTooltip }}"
                     placement="bottom"
                  />
               </label>
               <div class="input-and-unit-container">
                  <input
                     type="number"
                     class="form-control"
                     [(ngModel)]="partSpendEfficiencyRate"
                     min="-1"
                     onclick="this.focus();"
                  />
                  <lim-ui-minimal-icon-button
                     icon="rotateLeft"
                     placement="right"
                     [limUiTooltip]="lang().Reset"
                     (click)="resetToDefault('partsRate')"
                  />
               </div>
            </div>
            <div class="form-group">
               <label>
                  {{ lang().POSpendEfficiencyRate }}
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     limUiTooltip="{{ lang().POSpendEfficiencyRateTooltip }}"
                     placement="bottom"
                  />
               </label>
               <div class="input-and-unit-container">
                  <input
                     type="number"
                     class="form-control"
                     [(ngModel)]="poSpendEfficiencyRate"
                     min="-1"
                     onclick="this.focus();"
                  />
                  <lim-ui-minimal-icon-button
                     icon="rotateLeft"
                     placement="right"
                     [limUiTooltip]="lang().Reset"
                     (click)="resetToDefault('posRate')"
                  />
               </div>
            </div>
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>

   <lim-ui-modal-footer>
      <lim-ui-primary-button (click)="submit()">
         {{ lang().Save }}
      </lim-ui-primary-button>
      <lim-ui-outlined-button (click)="close()">
         {{ lang().Cancel }}
      </lim-ui-outlined-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
