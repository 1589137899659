<lim-ui-panel [title]="lang().EfficiencySavingsROI" [showWatermark]="true">
   <div class="buttons-container" buttons>
      <lim-ui-minimal-icon-button
         icon="gearRegular"
         iconSize="medium-large"
         (click)="openROISettingsModal()"
         limUiTooltip="{{ lang().Settings }}"
         placement="top"
      />
   </div>
   <div class="message-area">
      <div class="message" [limbleHtml]="lang().ROIPanelDescription"></div>
      <br />
      <div class="message">
         <span [limbleHtml]="lang().WantToLearnHowToImproveTheseMetrics"></span>
      </div>
      <div class="message">
         <span [limbleHtml]="lang().ContactYourLimbleRep"></span>
      </div>
      <div class="message">
         <span
            class="link"
            (click)="openCommunity()"
            [limbleHtml]="lang().JoinTheLimbleCommunity"
         ></span>
      </div>
      <div class="message">
         <span
            class="link"
            (click)="openTraining()"
            [limbleHtml]="lang().SignUpForTraining"
         ></span>
      </div>

      <!-- TODO: Add this back in once we have the help center document -->
      <!-- <br />
      <div>
         <span
            class="link"
            [limbleHtml]="lang().LearnMore"
            (click)="openLearnMore()"
         ></span>
      </div> -->
   </div>

   @if (isLoading()) {
      <div>
         <div class="loading-icon">
            <lim-ui-loading-animation />
         </div>
      </div>
   }
   <div [ngClass]="{ 'roi-panel--hidden': isLoading() }">
      <div class="data-viewer-filters-wrapper">
         <data-viewer-filters
            [filters]="filters()"
            (set)="onSetFilter($event)"
            (remove)="onRemoveFilter($event)"
            [noMargins]="true"
         />
      </div>

      <div>
         <div class="card-container">
            <lim-ui-card
               [clickableStyling]="true"
               (click)="viewTileWidgetItems('downtimeHoursEfficiency')"
               limUiTooltip="{{ lang().DowntimeEfficiencyWidgetTooltip }}"
            >
               <lim-ui-icon class="dashboard-card-icon" icon="clock" iconSize="large" />
               <div class="dashboard-card-number">
                  {{ currencySymbol }}{{ downtimeSavingsVal() | betterNumber: "1.0-0" }}
               </div>
               <div class="dashboard-card-text">
                  {{ lang().Downtime }}
               </div>
            </lim-ui-card>
            <lim-ui-card
               [clickableStyling]="true"
               (click)="viewTileWidgetItems('laborCostEfficiency')"
               limUiTooltip="{{ lang().LaborEfficiencyWidgetTooltip }}"
            >
               <lim-ui-icon
                  class="dashboard-card-icon"
                  icon="moneyBill1"
                  iconSize="large"
               />
               <div class="dashboard-card-number">
                  {{ currencySymbol }}{{ laborSavingsVal() | betterNumber: "1.0-0" }}
               </div>
               <div class="dashboard-card-text">
                  {{ lang().Labor }}
               </div>
            </lim-ui-card>
            <lim-ui-card
               [clickableStyling]="true"
               (click)="viewTileWidgetItems('partCostEfficiency')"
               limUiTooltip="{{ lang().PartSpendEfficiencyWidgetTooltip }}"
            >
               <lim-ui-icon class="dashboard-card-icon" icon="gears" iconSize="large" />
               <div class="dashboard-card-number">
                  {{ currencySymbol }}{{ partsSavingsVal() | betterNumber: "1.0-0" }}
               </div>
               <div class="dashboard-card-text">
                  {{ lang().PartSpend }}
               </div>
            </lim-ui-card>
            <lim-ui-card
               [clickableStyling]="true"
               (click)="viewTileWidgetItems('costOfPOsEfficiency')"
               limUiTooltip="{{ lang().POSpendEfficiencyWidgetTooltip }}"
            >
               <lim-ui-icon
                  class="dashboard-card-icon"
                  icon="filePowerpoint"
                  iconSize="large"
               />
               <div class="dashboard-card-number">
                  {{ currencySymbol }}{{ posSavingsVal() | betterNumber: "1.0-0" }}
               </div>
               <div class="dashboard-card-text">
                  {{ lang().POSpend }}
               </div>
            </lim-ui-card>
         </div>

         <div class="full-width-card-container">
            <lim-ui-card>
               <div class="graph-header">
                  <span class="graph-title">
                     {{ lang().EfficiencySavingsOverTime }}
                     <lim-ui-icon
                        class="help-icon"
                        icon="circleQuestionRegular"
                        iconSize="medium"
                        limUiTooltip="{{
                           lang().EfficiencySavingsOverTimeWidgetTooltip
                        }}"
                  /></span>
               </div>
               <canvas id="roi-line-chart" (click)="viewLineItem($event)"></canvas>
               <div class="line-chart-legend">
                  <div class="graph-legend-item">
                     <span class="graph-legend-dot red"></span>
                     {{ lang().Downtime }}
                  </div>
                  <div class="graph-legend-item">
                     <span class="graph-legend-dot green"></span>
                     {{ lang().Labor }}
                  </div>
                  <div class="graph-legend-item">
                     <span class="graph-legend-dot blue"></span>
                     {{ lang().Parts }}
                  </div>
                  <div class="graph-legend-item">
                     <span class="graph-legend-dot orange"></span>
                     {{ lang().POs }}
                  </div>
               </div>
            </lim-ui-card>
         </div>
      </div>
   </div>
</lim-ui-panel>
